import { Api } from "@/services/Api.js";
// import window.axios from 'axios';

export class ConfiguracaoService extends Api {
  constructor() {
    super();
    // this.headers = {}
    this.headers = this.getHeaders();
  }

  listar() {
    return window.axios.get(
      `${this.API_URI}/api/configuracoes`,
      { headers: this.headers }
    );
  }

}
