import { Api } from "@/services/Api.js";
// import window.axios from 'axios';

export class MenuService extends Api {

    constructor() {
        super();
        // this.headers = {}
        this.headers = this.getHeaders()
    }

    criar(record) {
        // 'Authorization': this.getToken()
        return window.axios.post(`${this.API_URI}/api/configuracoes/menus`, record, { headers: this.headers })
    }

    listar(queryString) {
        queryString = super.getQueryString(queryString);
        return window.axios.get(`${this.API_URI}/api/configuracoes/menus?${queryString}`, { headers: this.headers });
    }

    buscar(id) {
        return window.axios.get(`${this.API_URI}/api/configuracoes/menus/${id}`, { headers: this.headers });
    }

    atualizar(record) {
        return window.axios.put(`${this.API_URI}/api/configuracoes/menus/${record.id}`, record, { headers: this.headers })
    }

    deletar(id) {
        return window.axios.delete(`${this.API_URI}/api/configuracoes/menus/${id}`, { headers: this.headers });
    }

    vincularPermissao(menu_id, permissao_id){
        return window.axios.patch(`${this.API_URI}/api/configuracoes/menus/${menu_id}/vincular-permissao/${permissao_id}`,{} ,{ headers: this.headers })
    }
    
    desvincularPermissao(menu_id, permissao_id){
        return window.axios.patch(`${this.API_URI}/api/configuracoes/menus/${menu_id}/desvincular-permissao/${permissao_id}`, {}, { headers: this.headers })
    }

 

}