import { Api } from "@/services/Api.js";
// import window.axios from 'axios';

export class DownloadService extends Api {

    constructor() {
        super();
        // this.headers = {}
        this.headers = this.getHeaders()
    }

    download(record) {
        return window.axios.get(`${this.API_URI}/api/download/${record.external_id}`,  { responseType: 'blob', headers: this.headers })
    }

   


}