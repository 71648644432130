import { Api } from "@/services/Api.js";
// import window.axios from 'axios';

export class VeiculoAnoService extends Api {
  constructor() {
    super();
    // this.headers = {}
    this.headers = this.getHeaders();
  }

  criar(record) {
    // 'Authorization': this.getToken()
    return window.axios.post(`${this.API_URI}/api/outros/veiculo-ano`, record, {
      headers: this.headers,
    });
  }

  listar(queryString) {
    queryString = super.getQueryString(queryString);
    return window.axios.get(
      `${this.API_URI}/api/outros/veiculo-ano?${queryString}`,
      { headers: this.headers }
    );
  }

  buscar(id) {
    return window.axios.get(`${this.API_URI}/api/outros/veiculo-ano/${id}`, {
      headers: this.headers,
    });
  }

  atualizar(record) {
    return window.axios.put(
      `${this.API_URI}/api/outros/veiculo-ano/${record.id}`,
      record,
      { headers: this.headers }
    );
  }

  deletar(id) {
    return window.axios.delete(`${this.API_URI}/api/outros/veiculo-ano/${id}`, {
      headers: this.headers,
    });
  }
  
  uploadLogo(marca_id, data) {
    return window.axios.post(
      `${this.API_URI}/api/outros/veiculo-ano/${marca_id}/upload-logo`,
      data,
      { headers: this.headers }
    );
  }
  
}
