import { Api } from "@/services/Api.js";
// import window.axios from 'axios';

export class CambioService extends Api {
  constructor() {
    super();
    // this.headers = {}
    this.headers = this.getHeaders();
  }

  criar(record) {
    // 'Authorization': this.getToken()
    return window.axios.post(
      `${this.API_URI}/api/configuracoes/cambios`,
      record,
      { headers: this.headers }
    );
  }

  listar(queryString) {
    queryString = super.getQueryString(queryString);
    return window.axios.get(
      `${this.API_URI}/api/configuracoes/cambios?${queryString}`,
      { headers: this.headers }
    );
  }

  buscar(id) {
    return window.axios.get(`${this.API_URI}/api/configuracoes/cambios/${id}`, {
      headers: this.headers,
    });
  }

  atualizar(record) {
    return window.axios.put(
      `${this.API_URI}/api/configuracoes/cambios/${record.id}`,
      record,
      { headers: this.headers }
    );
  }

  deletar(id) {
    return window.axios.delete(
      `${this.API_URI}/api/configuracoes/cambios/${id}`,
      { headers: this.headers }
    );
  }

  listarMoedas(queryString) {
    queryString = super.getQueryString(queryString);
    return window.axios.get(
      `${this.API_URI}/api/configuracoes/moedas?${queryString}`,
      { headers: this.headers }
    );
  }

  listarHistorico(id) {
    return window.axios.get(
      `${this.API_URI}/api/configuracoes/cambios/${id}/historico`,
      {
        headers: this.headers,
      }
    );
  }

  atualizarTaxas(record) {
    // 'Authorization': this.getToken()
    return window.axios.post(
      `${this.API_URI}/api/configuracoes/cambios/taxas/atualizar`,
      record,
      { headers: this.headers }
    );
  }
}
