import { Api } from "@/services/Api.js";
// import window.axios from 'axios';

export class DashboardService extends Api {

    buscar(queryString) {
        let headers = this.getHeaders()
        queryString = super.getQueryString(queryString);
        return window.axios.get(`${this.API_URI}/api/dashboard?${queryString}`, { headers: headers });
    }

       

}