import { Api } from "@/services/Api.js";
// import window.axios from 'axios';

export class PublicService extends Api {

    constructor() {
        super();
    }

    getCompany() {
        return window.axios.get(`${this.API_URI}/api/public/company`);
    }

}