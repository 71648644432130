import { Api } from "@/services/Api.js";
// import window.axios from 'axios';

export class VendaService extends Api {
  constructor() {
    super();
    // this.headers = {}
    this.headers = this.getHeaders();
  }

  listar(queryString) {
    queryString = super.getQueryString(queryString);
    return window.axios.get(
      `${this.API_URI}/api/ecommerce/vendas?${queryString}`,
      { headers: this.headers }
    );
  }

  buscar(id) {
    return window.axios.get(`${this.API_URI}/api/ecommerce/vendas/${id}`, {
      headers: this.headers,
    });
  }

  buscarPagamentos(uuid) {
    return window.axios.get(`${this.API_URI}/api/ecommerce/vendas/${uuid}/pagamentos`, {
      headers: this.headers,
    });
  }

  atualizarStatus(uuid, obj) {
    return window.axios.put(`${this.API_URI}/api/ecommerce/vendas/${uuid}/atualizar-status`, obj,{
      headers: this.headers,
    });
  }
  
  estornar(uuid, obj) {
    return window.axios.put(`${this.API_URI}/api/ecommerce/vendas/${uuid}/estornar`, obj,{
      headers: this.headers,
    });
  }
 
  entregar(uuid, obj) {
    return window.axios.put(`${this.API_URI}/api/ecommerce/vendas/${uuid}/entregar`, obj,{
      headers: this.headers,
    });
  }

  
 
  
}
