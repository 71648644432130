<template>
  <div>
    <Loader :isVisible="data.isLoading && enableLoaderStorage">
    </Loader>
    <ThemeProvider
      :theme="{
        rtl,
        topMenu,
        darkMode,
        mainContent,
        ...themeColor,
      }"
    >
    <Suspense>
      <template #default>
          <div style="padding: 10px 20px 10px 20px">
            <router-view></router-view>
          </div>
        </template>
        <template #fallback>
          <Loader class="spin" :isVisible="true">
          </Loader>
        </template>
      </Suspense>
    </ThemeProvider>

  </div>
</template>
<script>
import { ThemeProvider } from "vue3-styled-components";
import { themeColor } from "./config/theme/themeVariables";
import { computed, defineComponent, onMounted, reactive } from "vue";
import { useStore } from "vuex";
import "v-calendar/dist/style.css";
import Loader from '@/components/loader/Loader';
import { ConfiguracaoService } from "@/services";

export default defineComponent({
  name: "App",
  components: {
    ThemeProvider,
    Loader
  },
  setup() {
    const {  state, commit } = useStore();
    const store = useStore();
    const rtl = computed(() => state.themeLayout.rtlData);
    const enableLoaderStorage = computed(() => state.loader.enableLoader);
    const isLoading = computed(() => state.themeLayout.loading);
    const darkMode = computed(() => state.themeLayout.data);
    const topMenu = computed(() => state.themeLayout.topMenu);
    const mainContent = computed(() => state.themeLayout.main);


    // watch(
    //   () => enableLoaderStorage.value,
    //   () => {
    //     if (enableLoaderStorage.value) {
    //       enabledLoader()
    //     }else{
    //       disabledLoader()
    //     }
    //   }
    // );

    onMounted(() => {

      getConfiguracoes();

      enabledLoader();
      window.addEventListener("load", () => {
        const domHtml = document.getElementsByTagName("html")[0];
        rtl.value
          ? domHtml.setAttribute("dir", "rtl")
          : domHtml.setAttribute("dir", "ltr");
        darkMode.value ? document.body.classList.add("dark-mode") : "";
      });

      setTimeout(() => {
        store.dispatch('auth/me');
      }, 1000);
      
      setInterval( () => {
        store.dispatch('auth/ping');
      }, 300000) // checa se está logado a cada 5 min

    });
    
    const data = reactive({
      axiosInterceptor : null,
      isLoading: false
    })

    const disabledLoader = () => {
      window.axios.interceptors.request.eject(data.axiosInterceptor);
      addLangQueryParam();
    }

    const enabledLoader = () => {
      data.axiosInterceptor = window.axios.interceptors.request.use(
            config => {
              if(['post','put','delete'].includes(config.method)){
                data.isLoading = true;
              }
              return config;
            },
            error => {
              data.isLoading = false; 
              return Promise.reject(error);
            }
          );
  
          window.axios.interceptors.response.use(
            response => {
              data.isLoading = false; 
              return response;
            },
            error => {
              data.isLoading = false; 
              return Promise.reject(error);
            }
          );
        addLangQueryParam();
    };

    const addLangQueryParam = () => {
      window.axios.interceptors.request.use(
        config => {
          // Obtenha o lang do localStorage ou de algum lugar onde você esteja armazenando o locale atual
          const lang = store.getters["lang/getLang"]

          // Adicione o parâmetro lang à URL
          const url = new URL(config.url, window.location.origin);
          url.searchParams.set('lang', lang);
          config.url = url.toString();

          return config;
        },
        error => {
          return Promise.reject(error);
        }
      );
    };

    const getConfiguracoes = () => {
      let configuracaoService = new ConfiguracaoService();
      configuracaoService.listar()
        .then(response => { 
          commit('cambios/SET_CAMBIO', response.data.cambios)
          commit('cambios/SET_MOEDA', response.data.moeda_padrao)
        }).catch( error => {
          console.log("Erro ao buscar as configurações: ", error  )
          setTimeout(() => {
            getConfiguracoes()
          }, 2000);
        })
    }


    return {
      themeColor,
      rtl,
      darkMode,
      topMenu,
      isLoading,
      mainContent,
      data,
      enabledLoader,
      disabledLoader,
      enableLoaderStorage
    };
  },
});
</script>
