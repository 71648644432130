import { Api } from "@/services/Api.js";
// import window.axios from 'axios';

export class UserService extends Api {

    constructor() {
        super();
        // this.headers = {}
        this.headers = this.getHeaders()
    }

    criar(record) {
        // 'Authorization': this.getToken()
        return window.axios.post(`${this.API_URI}/api/administracao/usuarios`, record, { headers: this.headers })
    }

    listar(queryString) {
        queryString = super.getQueryString(queryString);
        console.log({ headers: this.headers })
        return window.axios.get(`${this.API_URI}/api/administracao/usuarios?${queryString}`, { headers: this.headers });
    }

    buscar(id) {
        return window.axios.get(`${this.API_URI}/api/administracao/usuarios/${id}`, { headers: this.headers });
    }

    atualizar(record) {
        return window.axios.put(`${this.API_URI}/api/administracao/usuarios/${record.id}`, record, { headers: this.headers })
    }

    deletar(id) {
        return window.axios.delete(`${this.API_URI}/api/administracao/usuarios/${id}`, { headers: this.headers });
    }

    trocarSenha(form) {
        return window.axios.post(`${this.API_URI}/api/administracao/usuarios/${form.id}/trocar-senha`, form, { headers: this.headers });
    }

    vincularPerfil(user_id, perfil_id){
        return window.axios.patch(`${this.API_URI}/api/administracao/usuarios/${user_id}/vincular-perfil/${perfil_id}`,{} ,{ headers: this.headers })
    }
    
    desvincularPerfil(user_id, perfil_id){
        return window.axios.patch(`${this.API_URI}/api/administracao/usuarios/${user_id}/desvincular-perfil/${perfil_id}`, {}, { headers: this.headers })
    }
}