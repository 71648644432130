const state = () => ({
  cambios: [],
  moeda_padrao: null,
});

const mutations = {
  SET_CAMBIO(state, cambios) {
      state.cambios = cambios
  } ,
  SET_MOEDA(state, moeda) {
    console.log("Moeda Padrão Setada: ", moeda)
    state.moeda_padrao = moeda
  } 
}

const getters = {

  getCambios(state) {
    return state.cambios
  },
  moeda_padrao(state) {
    return state.moeda_padrao
  },

  getTaxa: (state) => (moeda_origem, moeda_destino) => {
    console.log(state, moeda_destino, moeda_origem)
    // Lógica pra achar a moeda origem e moeda destino

    return null
  },

  getvalorConvertido: (state) => (moeda_origem, valor,  moeda_destino, taxa) => {
    console.log(state, moeda_destino, moeda_origem, valor, taxa)
    // acha a taxa se vier vazia

    // aplica a logica de conversao
    // returna
    return null
  }

}

const actions = {
   
  buscarCambio({state, commit}){
    console.log(state, commit)
    // Buscar o cambio no backend e comittar  SET_CAMBIO
  }

}


export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
};
