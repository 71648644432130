import { Api } from "@/services/Api.js";
// import window.axios from 'axios';

export class PerfilService extends Api {

    constructor() {
        super();
        // this.headers = {}
        this.headers = this.getHeaders()
    }

    criar(record) {
        // 'Authorization': this.getToken()
        return window.axios.post(`${this.API_URI}/api/administracao/perfis`, record, { headers: this.headers })
    }

    listar(queryString) {
        queryString = super.getQueryString(queryString);
        return window.axios.get(`${this.API_URI}/api/administracao/perfis?${queryString}`, { headers: this.headers });
    }

    buscar(id) {
        return window.axios.get(`${this.API_URI}/api/administracao/perfis/${id}`, { headers: this.headers });
    }

    atualizar(record) {
        return window.axios.put(`${this.API_URI}/api/administracao/perfis/${record.id}`, record, { headers: this.headers })
    }

    deletar(id) {
        return window.axios.delete(`${this.API_URI}/api/administracao/perfis/${id}`, { headers: this.headers });
    }

    vincularPermissao(perfil_id, permissao_id){
        return window.axios.patch(`${this.API_URI}/api/administracao/perfis/${perfil_id}/vincular-permissao/${permissao_id}`,{} ,{ headers: this.headers })
    }
    
    desvincularPermissao(perfil_id, permissao_id){
        return window.axios.patch(`${this.API_URI}/api/administracao/perfis/${perfil_id}/desvincular-permissao/${permissao_id}`, {}, { headers: this.headers })
    }


}