import { Api } from "@/services/Api.js";
// import window.axios from 'axios';

export class BannerService extends Api {

    constructor() {
        super();
        // this.headers = {}
        this.headers = this.getHeaders()
    }

    criar(record) {
        // 'Authorization': this.getToken()
        return window.axios.post(`${this.API_URI}/api/administracao/banners`, record, { headers: this.headers })
    }

    listar(queryString) {
        queryString = super.getQueryString(queryString);
        console.log({ headers: this.headers })
        return window.axios.get(`${this.API_URI}/api/administracao/banners?${queryString}`, { headers: this.headers });
    }

    buscar(id) {
        return window.axios.get(`${this.API_URI}/api/administracao/banners/${id}`, { headers: this.headers });
    }

    atualizar(record) {
        return window.axios.put(`${this.API_URI}/api/administracao/banners/${record.id}`, record, { headers: this.headers })
    }

    deletar(id) {
        return window.axios.delete(`${this.API_URI}/api/administracao/banners/${id}`, { headers: this.headers });
    }

    uploadImagem(banner_id, data) {
        return window.axios.post(
          `${this.API_URI}/api/administracao/banners/${banner_id}/upload-imagem`,
          data,
          { headers: this.headers }
        );
      }

}