import { Api } from "@/services/Api.js";
// import window.axios from 'axios';

export class MoedaRegraService extends Api {

    constructor() {
        super();
        // this.headers = {}
        this.headers = this.getHeaders()
    }

    criar(record) {
        // 'Authorization': this.getToken()
        return window.axios.post(`${this.API_URI}/api/configuracoes/moeda-regras`, record, { headers: this.headers })
    }

    listar(queryString) {
        queryString = super.getQueryString(queryString);
        return window.axios.get(`${this.API_URI}/api/configuracoes/moeda-regras?${queryString}`, { headers: this.headers });
    }

    buscar(id) {
        return window.axios.get(`${this.API_URI}/api/configuracoes/moeda-regras/${id}`, { headers: this.headers });
    }

    atualizar(record) {
        return window.axios.put(`${this.API_URI}/api/configuracoes/moeda-regras/${record.id}`, record, { headers: this.headers })
    }

    deletar(id) {
        return window.axios.delete(`${this.API_URI}/api/configuracoes/moeda-regras/${id}`, { headers: this.headers });
    }


}