import { Api } from "@/services/Api.js";
// import window.axios from 'axios';

export class CidadeService extends Api {

    constructor() {
        super();
        // this.headers = {}
        this.headers = this.getHeaders()
    }

    criar(record) {
        // 'Authorization': this.getToken()
        return window.axios.post(`${this.API_URI}/api/administracao/cidades`, record, { headers: this.headers })
    }

    listar(queryString) {
        queryString = super.getQueryString(queryString);
        return window.axios.get(`${this.API_URI}/api/administracao/cidades?${queryString}`, { headers: this.headers });
    }

    buscar(id) {
        return window.axios.get(`${this.API_URI}/api/administracao/cidades/${id}`, { headers: this.headers });
    }

    atualizar(record) {
        return window.axios.put(`${this.API_URI}/api/administracao/cidades/${record.id}`, record, { headers: this.headers })
    }

    deletar(id) {
        return window.axios.delete(`${this.API_URI}/api/administracao/cidades/${id}`, { headers: this.headers });
    }

    buscarCep(cep){
        let viacep_url = process.env.VUE_APP_VIACEP_URL || ''
        let x = cep.match(/\d+/g).join('')
        return window.axios.get(`${viacep_url}/ws/${x}/json`);
    }   

}