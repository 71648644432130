import { Api } from "@/services/Api.js";
// import window.axios from 'axios';

export class ProdutoService extends Api {
  constructor() {
    super();
    // this.headers = {}
    this.headers = this.getHeaders();
  }

  criar(record) {
    // 'Authorization': this.getToken()
    return window.axios.post(`${this.API_URI}/api/cadastros/produtos`, record, {
      headers: this.headers,
    });
  }

  listar(queryString) {
    queryString = super.getQueryString(queryString);
    return window.axios.get(
      `${this.API_URI}/api/cadastros/produtos?${queryString}`,
      { headers: this.headers }
    );
  }

  buscar(id) {
    return window.axios.get(`${this.API_URI}/api/cadastros/produtos/${id}`, {
      headers: this.headers,
    });
  }

  atualizar(record) {
    return window.axios.put(
      `${this.API_URI}/api/cadastros/produtos/${record.id}`,
      record,
      { headers: this.headers }
    );
  }

  deletar(id) {
    return window.axios.delete(`${this.API_URI}/api/cadastros/produtos/${id}`, {
      headers: this.headers,
    });
  }
  upload(produto_id, data) {
    return window.axios.post(
      `${this.API_URI}/api/cadastros/produtos/${produto_id}/upload-imagens`,
      data,
      { headers: this.headers }
    );
  }

  excluirImagem(produto_id, id) {
    return window.axios.delete(
      `${this.API_URI}/api/cadastros/produtos/${produto_id}/excluir-imagem/${id}`,
      { headers: this.headers }
    );
  }

  atualizarPreco(id, dadosPreco) {
    return window.axios.post(
      `${this.API_URI}/api/cadastros/produtos/${id}/atualizar-precos`,
      dadosPreco, 
      { headers: this.headers }
    );
  }
  
  
  atualizarEspecificacoes(id, especificacoes) {
    return window.axios.put(
      `${this.API_URI}/api/cadastros/produtos/${id}/atualizar-especificacoes`,
      especificacoes, 
      { headers: this.headers }
    );
  }


  atualizarMetaImagens(id, obj){
    return window.axios.put(
      `${this.API_URI}/api/cadastros/produtos/${id}/atualizar-meta-imagens`,
      obj, 
      { headers: this.headers }
    );
  }

  

}
