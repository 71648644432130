<template>
  <span :style="data.style" style="padding:5px 7px 5px 7px; text-align: center; display: inline-block; border-radius: 10px; font-size: 12px; font-weight: 500;">
    {{ data.label }}
  </span>
</template>

<script>
import { defineComponent, computed } from "vue";

export default defineComponent({
  name: "StatusVenda",
  props: {
    status: {
      type: String,
      default: '' 
    }
  },
  setup(props) {

    
    const data = computed(() => {
      switch(props.status){
        case 'CARRINHO'  : return{
          label: 'Carrinho',
          style:{
            'background-color': '#FA8B0C15',
            'color': '#FA8B0C',
          },
        };
        case 'ABANDONADO'  : return{
          label: 'Abandonado',
          style:{
            'background-color': '#FF0F0F15',
            'color': '#FF0F0F',
          },
        };
        case 'PAGAMENTO' : return{
          label: 'Pagamento',
          style:{
            'background-color': '#01B81A15',
            'color': '#01B81A',
          },
        };
        case 'PAGO' : return{
            label: 'Pago',
            style:{
              'background-color': '#01B81A15',
              'color': '#01B81A',
            },
        };
        case 'FATURAMENTO' : return{
            label: 'Faturamento',
            style:{
              'background-color': '#01B81A15',
              'color': '#01B81A',
            },
        };
        case 'EMBALAGEM' : return{
            label: 'Embalagem',
            style:{
              'background-color': '#01B81A15',
              'color': '#01B81A',
            },
        };
        case 'PRONTO_ENTREGA' : return{
            label: 'Pronto p/ Entrega',
            style:{
              'background-color': '#01B81A15',
              'color': '#01B81A',
            },
        };
        case 'RETIRADO' : return{
            label: 'Retirado',
            style:{
              'background-color': '#01B81A15',
              'color': '#01B81A',
            },
        };
        case 'ENVIADO' : return{
            label: 'Enviado',
            style:{
              'background-color': '#01B81A15',
              'color': '#01B81A',
            },
        };
        case 'ENTREGUE' : return{
            label: 'Entregue',
            style:{
              'background-color': '#01B81A15',
              'color': '#01B81A',
            },
        };
        case 'ESTORNADO' : return{
            label: 'Estornado',
            style:{
              'background-color': '#01B81A15',
              'color': '#01B81A',
            },
        };
        case 'DEVOLVIDO' : return{
            label: 'Devolvido',
            style:{
              'background-color': '#01B81A15',
              'color': '#01B81A',
            },
        };
      
        default  : return{
            style:{
              'background-color': '#FF0F0F15',
              'color': '#FF0F0F',
            },
        };
      }
    });

    return {
      data
    };
  },
});
</script>
