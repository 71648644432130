import { format, parseISO } from 'date-fns';

export function formatDate(data){
    if (!data)
        return data
    
    if(typeof data === 'string'){
        data = parseISO(data);
    }

    return format(data, 'dd/MM/yyyy');

}

export function formatDateTime(data){
    if (!data)
        return data

    if(typeof data === 'string'){
        data = parseISO(data);
    }

    return format(data, 'dd/MM/yyyy HH:mm:ss');

}

export function formatCurrency(valor, simbolo = '', decimais = 2){
    
    if (typeof valor === 'string') {
        const parsed = parseFloat(valor);
        if (isNaN(parsed)) {
            // Retorna o valor original se não for um número válido
            return valor;
        }
        valor = parsed;
    }

    // Verifica se o valor é um número após a conversão
    if (typeof valor !== 'number' || isNaN(valor)) {
        return valor;
    }

    // Formata o valor como moeda com o número de decimais
    return `${simbolo} ${valor.toFixed(decimais)
        .replace('.', ',') // Substitui ponto decimal por vírgula
        .replace(/\B(?=(\d{3})+(?!\d))/g, '.')}`.trim(); // Adiciona pontos para milhares
}